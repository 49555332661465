.businessVerificationUnderReview{
    /* background-image: linear-gradient(to bottom, #FFD242, #FFEC65); */
    background-color: white;
    width: 90%;
    margin: auto;
    padding-top: 1px;
    padding-bottom: 35px;
}

.businessVerificationUnderReview h2{
    font-size: 16px;
    margin-top: 25px;
    font-weight: 600;
}

.businessVerificationUnderReview p{
    font-weight: 500;
    color: #ac843c;
    margin: auto;
    width: 80%;
}