.button:hover {
    cursor: pointer;
  }

.starfruitBusinesspageFirstBackgroundDiv{
    width: 100%;
  padding: 0;
  position: absolute;
  height: 660px;
  top:60px;
  z-index: -10;
  /* border: 1px solid red; */
  overflow: hidden;
}

.individualReviewsDemo{
    /* padding-bottom: 20px; */
}

.individualReviewsDivDemo{
    border-radius: 5px;
    padding-top: 3px;
}

.individualReviewDivDemo{
    background-color: white;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 5px;
    padding: 17px;
    padding-top: 12px;
    padding-bottom: 18px;
    /* padding-top: 18px; */
    /* padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px; */
    margin-bottom: 13px;
    margin-top: 13px;
}

.individualReviewDateDivDemo p{
    /* width: max-content; */
    display: inline-block;
    margin-left: 8px;
    font-size: 15px;
    /* color: black; */
    color: rgb(75, 75, 75);
    font-weight: 400;
    vertical-align: middle;
    line-height: normal;
    margin-top: -15px;
    /* margin-bottom: 15px; */
}


.individualReviewRatingDivDemo p{
    display: inline-block;
    /* margin-right: 5px; */
    font-size: 12.5px;
    padding: 0px;
    margin: 0;
    color: rgb(69, 69, 69);
}

.individualReviewRatingDivDemo button{
    /* margin-left: 10px;
    background-color: rgb(245, 244, 241);
    border: none;
    border-radius: 5px;
    height: 28px;
    width:100px; */
    background-color: white;
    color: rgb(165, 165, 165);
    height: 33px;
    margin-left: 12px;
    /* width: 95px; */
    border-radius: 5px;
    border: 1px solid rgb(236, 236, 236);
    font-size: 14px;
}

.individualReviewMoreLargeButtonDemo{
    width: 95px;
    display: inline;
}

.individualReviewMoreSmallButtonDemo{
    width: 35px;
    display: none;
}

.individualReviewRatingDivDivDemo{
    display: flex;
    margin-top: 13px;
}

.individualReviewRatingDivItemDemo{
    width: calc(25%);
    /* margin-right: 10px; */
    height: 53px;
    /* background-color: red; */
    border: 1px solid rgb(240, 240, 240);
    border-radius: 5px;
    text-align: center;
}

.individualReviewRatingDivItemDemo p{
    /* font-size: 12px; */
    font-size: 12.5px;
    /* padding-top: 7px; */
    padding-top: 10px;
}

.individualReviewRatingDivItemDemo h3{
    font-size: 16px;
    margin-top: 1px;
    font-weight: 400;
    color: rgb(75, 75, 75);
}

.individualReviewNotesDivDemo{
    /* background-color: pink; */
    margin-top: 15px;
    margin-bottom: 2px;
    /* margin-left: 5px;
    margin-right: 5px; */
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 7px;
    padding-bottom: 7px;
    min-height: 30px;
    border: 1px solid rgb(240, 240, 240);
    /* border: 1px solid #d9d9d9; */
    border-radius: 2px;
    line-height: 21.5px;
    background-color: rgb(252, 252, 252);
    color: rgb(75, 75, 75);
    /* background-color: #f0ece4;
    border-radius: 2px;
    padding-left: 8px;
    padding-right: 8px; */
    max-width: 100%;
    font-size: 15.5px;
}

.individualReviewNotesDivDemo p{
    margin-top: 3px;
    margin-bottom: 3px;

}

.individualReviewsDemo input{
    /* background-color: pink; */
    height: 25px;
    /* margin-right: 5px; */
    width: calc(100% - 36px);
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-left: 5px;
    font-size: 15px;
}

.individualReviewsDiv p {
    margin-bottom: 10px;
    margin-top: 5px;
}

.summaryDiv p{
    margin: 0px;
}

.starfruitBusinesspageFirstBackground{
/* width: */
    /* top: 50%; */
    /* left: 50%; */
    /* width: 100%; */
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.starfruitBusinesspageSecondBackgroundDiv{
    width: 100%;
  padding: 0;
  position: absolute;
  height: 680px;
  top:600px;
  z-index: -10;
    /* border: 1px solid red; */
    overflow: hidden;
    /* width: 100%;
  padding: 0;
  position: absolute;
  height: 450px; 
  top:600px; */

  /* z-index: -10; */

}

.starfruitBusinesspageSecondBackground{
    max-height: 450px;
    position: absolute;
    top: 80%;
    left: 50%;
    width: 150%;
    transform: translate(-55%, -50%);
}

.businesspageBodySecondBackground{
    width: 100%;
    height: 620px;
    /* padding-top: 3%; */
    z-index: -1000;
    position: absolute;
    background-image: linear-gradient(#FFBE1F, #FFDC29);
}

.starfruitBusinesspageThirdBackgroundDiv{
    width: 100%;
  padding: 0;
  position: absolute;
  height: 620px;
  top:1180px;
  z-index: -10;
    /* border: 1px solid red; */
    overflow-y: hidden;
    /* width: 100%;
  padding: 0;
  position: absolute;
  height: 450px;
  top:600px; */
  /* z-index: -10; */
}

.starfruitBusinesspageThirdBackground{
    /* max-height: 450px; */
    position: absolute;
    top: 80%;
    left: 50%;
    width: 180%;
    z-index: -5;
    transform: translate(-55%, -50%);
}

.businesspageBodyThirdBackground{
    width: 100%;
    /* height: 620px; */
    /* padding-top: 3%; */
    /* z-index: -10;
    position: absolute; */
    background-image: linear-gradient(to left, #FFBE1F, #FFDC29);
    padding-bottom: 70px;
}


.businessPageBody .styles_faq-row-wrapper__3vA1D{
    border:none
}

.businessPageBody .styles_faq-row__2YF3c {
    border:none !important; 
}
.row-content-text {
    text-align: left;
}

.businessPageBody{
    /* position: absolute;
    top: 60px; */
    /* background-image: linear-gradient(to bottom, #FFD242, #FFEC65); */
    /* width: 100%; */
    /* height: calc(100% - 80px); */
    /* padding-top: 5%; */
    /* padding-bottom: 50px; */
}

.businessIntro {
    /* background-image: linear-gradient(to bottom, #FFD242, #FFEC65); */
    width: 100%;
    height: calc(100vh - 180px);
    min-height: 660px;
    /* padding-top: 3%;
    padding-top: 3%; */
    /* height: calc(100vh - 100px); */
    /* min-height: 650px; */
}

.businessIntroFirstTitle {
    font-size: 28px;
    font-weight: 600;
    /* margin-bottom: 25px; */
    margin-top: 60px;
    /* z-index: -90; */
}

.faqDivInner button{
    padding: 8px;
    height: 39px;
    font-size: 15px;
    margin-right: 15px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 0px;
    font-weight: 600;
}

.faqDivInner h1{
    font-size: 28px;
    font-weight: 600;
    margin-top: 0px;
}

.businesspageBodyFourthBackground h1{
    font-size: 28px;
    font-weight: 600;
    margin-top: 0px;
    padding-top: 40px;
    /* margin-bottom: -5px; */
}



.businessIntro p{
    /* color: #ac843c;
    width: 70%;
    margin: auto; */
    width: 80%;
    margin: auto;
    /* margin-top: 30px; */
    /* color: #ac843c; */
    /* color: rgb(190, 156, 33); */
    /* color: white; */
    color: rgb(169 132 0);
    font-weight: 500;
    font-size: 17px;
    line-height: 1.45;
}

.businessIntro h4{
    color: #ac843c;
    font-weight: 300;
}

.starfruitBusinesspageFirstInfographic {
    width: 76%;
    max-width: 450px;
    min-width: 340px;
    margin-bottom: 50px;
    margin-left: 15px;
    /* position: absolute;
    top: 300px;
    left: 6%; */
    margin-top: 45px;
}
 
.businessIntro button{
    border: none;
    border-radius: 5px;
    margin-top: 45px;
    margin-bottom: 20px;
    font-weight: 600;
    /* padding: 10px; */
    font-size: 15px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
    padding: 8px;
    height: 39px;  
}

#loginButton{
    background-color: white;
    border: 2px solid white;
    width: 100px;
}
#getQRCodeButton{
    /* background-color: rgb(255, 200, 0); */
    background-color: transparent;
    border: 2px solid white;
    color: white;
    width: 140px;
}

.analyticsDemoInner{
    width: 86%;
    margin: auto;
    text-align: left;
}

.analyticsDemoInner h2{
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
    margin-top: 45px;
}

.dashboardInputDemo{
    margin-top: 0px;
    margin-bottom: 5px;
    padding-top: 8.5px;
    font-size: 16px;
}

.faqDivInner{
    width: 86%;
    margin: auto;

}

.faqDivInner{
    text-align: left;
    /* height: 500px; */
}

.faqDivInner h1{
    margin-bottom: 10px;
    padding-top: 40px;
/* color: white; */
}

.faqDivOuter{
    /* padding-top: 3%; */
    width: 100%;
    /* padding-top: 30px; */
    /* height: 550px; */
    /* background-image: linear-gradient(to bottom, #FFD242, #FFEC65); */
    /* margin-top: 100px; */
}

.starfruitBusinesspageThirdInfographic{
    width: 60%;
    max-width: 240px;
    padding-top: 60px;
    /* margin-bottom: 50px; */
    /* position: absolute;
    left: 50%;
    transform: translate(-55%, -50%); */

}

.faqDivInnerInner{
    background-color: white;
    margin-top: 30px;
    padding-left: 25px;
    padding-right: 18px;
    padding-bottom: 15px;
    border-radius: 5px;
    border: 1.5px solid rgb(240, 240, 240);
}

.faqDivThird{
    /* padding-top: 3%; */
    height: 100%;
    width: 100%;
    /* background-image: linear-gradient(#FFBE1F, #FFDC29); */
}

.faqDivThird h1{
    /* float: right; */
    /* text-align: right; */
    /* padding-right: 20px; */
    padding-bottom: 15px;
}
.faqDivThird p{
    /* float: right; */
    width: 90%;
    margin: auto;
}

.starfruitBusinesspageSecondInfographic{
    width: 62%;
    max-width: 300px;
    padding-top: 50px;
    /* padding-bottom: 60px; */
    margin-left: -30px;
}

.businessPageBodyThirdText{
    background-color: white;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    max-width: 700px;
    /* display: inline-block; */
}

.businesspageBodyFifthBackground{
    /* text-align: left; */
}

.businesspageBodyFifthBackground h1{
    font-size: 28px;
    font-weight: 600;
    margin-top: 0px;
    padding-top: 40px;
    /* width: 86%;
    margin: auto; */
}

.businesspageBodyFifthBackgroundInner{
    width: 88%;
    margin: auto;
}
.businesspageBodyFifthBackgroundInner p{
    /* color: rgb(190, 156, 33); */
    /* color: white; */
    /* color: rgb(169 132 0); */
    color: rgb(180, 180, 180);
    /* padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    background-color: white;
    border-radius: 8px; */
    font-weight: 400;
    font-size: 17px;
    line-height: 1.45;
    width: 80%;
    margin: auto;
    

}
.autoplaySlider{
    margin: auto;
    margin-top: 30px;
    max-height: 320px;
    max-width: 600px;
    /* border-top: 2.5px solid white; */
   /* border-bottom: 2.5px solid white; */
   /* border: 3px solid white; */
   /* padding-left: calc(1.15% + 0px);
   padding-right: calc(1.15% + 0px); */
   width: calc(80% ) !important;
   border-radius: 4px;
}

.awssld__bullets button{
    /* background-color: white !important; */
    background-color: rgb(255, 213, 0) !important;
    width: 8px !important;
    height: 8px !important;
}

.awssld__bullets {
    bottom: -18% !important;
}

.autoplaySliderPage{
    margin: auto;
    /* max-width: 900px; */
    max-height: 320px;
}

.awssld__container{
    padding-bottom: calc(var(--slider-height-percentage) / 1.5) !important;
}

.awssld__content{
background-color: transparent !important;
max-width: 600px;
position: relative !important;

/* background-image: linear-gradient(#FFBE1F, #FFDC29) !important; */
}

.autoplaySlider img{
    /* max-width: 900px; */
    max-height: 320px;
    max-width: 600px;
    position: relative !important;
    /* border-radius: 5px; */
}

.businesspageBodySixthBackground{
    padding-bottom: 70px;
}

.missionElementOuterDiv{
    /* display: flex; */
    display: inline;
}

.missionElementInnerDiv{
    display: flex;
    margin-bottom: 5px;
    /* background-color: green; */
    width: calc(100% + 20px);
}

.missionElement{
    width: calc(50%);
    margin-right: 20px;

}

.businesspageBodySixthBackgroundInner {
    width: 88%;
    margin: auto;
    padding-bottom: 20px;
}

.businesspageBodySixthBackgroundInnerInner{
    /* background-color: pink; */
    /* width: 90%; */
        /* margin: auto; */
}

.businesspageBodySixthBackgroundInner h1{
    margin-bottom: 10px;
}

.businesspageBodySixthBackground h1{
    font-size: 28px;
    font-weight: 600;
    margin-top: 0px;
    padding-top: 40px;
    /* width: 86%;
    margin: auto; */
}

.ownerProfileOuter{
    display: flex;
}

.ownerProfile:nth-child(1){
    margin-right: 20px;
}

.ownerProfile:nth-child(2){
    margin-right: 20px;
}

.ownerProfile{
    /* background-color: green; */
    /* background-image: linear-gradient(#FFBE1F, #FFDC29); */
    /* background-color: white; */
    background-color: rgb(254, 254, 254);
    /* border: 2px solid white; */
    width: 86%;
    margin: auto;
    text-align: left;
    padding: 17px;
    margin-bottom: -15px;
    margin-top: 40px;
    border-radius: 5px;
    min-height: 140px;
    border: 1.5px solid rgb(240, 240, 240);
    width: 33%;
}

.ownerProfileInner{
    display: flex;
}

/* .ownerProfilePicDiv{
    background-color: yellow;
    width: 50%;
} */


.ownerProfilePic{
    width: 65px;
    height: 65px;
    border-radius: 15px;


    /* display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden */
}

.ownerProfilePic img{
    max-width: 100%;
    max-height: 100%;
    border-radius: 10px;
}

.ownerProfileText{
    width: calc( 100%);
    /* background-color: blue; */
    margin-left: 12px;
    /* padding: 13px; */
    border-radius: 5px;
    /* height: 140px; */
}

.ownerProfileText h3{ 
    /* background-color: orange; */
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 17px;
    font-weight: 700;
}

.ownerProfileText h5{
    /* background-color: rgb(255, 200, 0); */
    /* background-color: rgb(255, 213, 0); */
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14.5px;
    color: rgb(255, 213, 0);
    border: 1px solid rgb(255, 213, 0);
    border-radius: 3px;
    margin-left: 7px;
    font-weight: 600;
    /* display: inline-block;
    height: 32px;
    background-color: rgb(255, 213, 0);
    color: white;
    width: 80px;
    border: none;
    border-radius: 3px;
    margin-top: 0px;
    font-weight: 600;
    margin-left: 7px;
    font-weight: 600; */
}

.ownerProfileText h4{
    /* padding: 5px; */
    margin-top: 5px;
    margin-bottom: 0px;
    color: rgb(180, 180, 180);
    font-weight: 400;
}

.ownerProfile p{
 background-color: #FCFCFC;
 /* background-color: blue; */
 border: 1px solid rgb(240, 240, 240);
 min-height: 40%;
 padding: 8px;
 padding-left: 10px;
 padding-right: 10px;
 margin-top: 9px;
 color: rgb(75, 75, 75);
 font-size: 15.5px;
 margin-bottom: 5px;
 line-height: 21.5px;
}

/* .ownerProfileTextLarge{
    display: none;
} */

.quoteIcon{
    color:  rgb(255, 213, 0);
    font-size: 13px;
}

.businesspageBodySeventhBackground h3{
    font-weight: 700;
    font-size: 18px;
    /* background-color: pink; */
    margin: 0px;
    /* margin-left: -26px;
    margin-top: 10.5px; */
    z-index: 100;
    /* margin-left: 3px; */
    /* color: white; */
}

.businesspageBodySeventhBackground h2{
    font-size: 18px;
    font-weight: 500;
    /* background-color: green; */
    margin: 0px;
    margin-left: 7px;
    /* margin-left: 25px;
    margin-top: 9px; */
}

.footerBusiness{
    z-index: 1;
}

/* .styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY{
    padding: 11px 0;
} */

/* .styles_faq-row-wrapper__3vA1D .styles_faq-row__2YF3c{
    border: none !important;
} */

@media screen and (max-width: 1300px) {
    .missionElementOuterDiv{
        display: inline;
    }
}

@media screen and (max-width: 1000px) {
    .awssld__container{
        padding-bottom: calc(var(--slider-height-percentage) /1.1) !important;
    }
}

@media screen and (max-width: 850px) {

}

@media screen and (max-width: 800px) {
    .ownerProfileOuter{
        display: inline;
    }
    
    .ownerProfile{
        width: 86%;
    }
    .ownerProfile:nth-child(1){
        margin-right: auto;
    }
    
    .ownerProfile:nth-child(2){
        margin-right: auto;
    }
    .missionElementInnerDiv{
        display: inline-block;
        /* background-color: green; */
    }
    
    .missionElement{
        width: calc(100% - 35px);
        margin-right: auto;
        /* margin-right: 0px !important; */
    }
    .missionIcon{
        display: none;
    }
    .missionElementInnerDiv{
        width: 100%;
    }
}


@media screen and (max-width: 700px) {
    .awssld__container{
        padding-bottom: calc(var(--slider-height-percentage) * 1.1) !important;
    }
}

@media screen and (max-width: 500px) {
    .businessIntroFirstTitle{
        margin-top: 70px;
    }
    .businessIntro {
        /* background-image: linear-gradient(to bottom, #FFD242, #FFEC65); */
        height: calc(100vh - 200px);
        /* padding-top: 3%;
        padding-top: 3%; */
        /* height: calc(100vh - 100px); */
        /* min-height: 650px; */
    }
    .individualReviewMoreLargeButtonDemo{
        display: none;
    }
    .individualReviewMoreSmallButtonDemo{
        width: 35px;
        display: inline;
    }

    .faqDivInnerInner{
        padding-left: 20px;
        padding-right: 15px;
    }

    .autoplaySlider{
       width: calc(90% ) !important;
    }

    /* .awssld__container{
        padding-bottom: calc(var(--slider-height-percentage) * 1.1) !important;
    } */
}

@media screen and (max-width: 600px) {
    /* .ownerProfilePic{
        width: 65px;
        height: 65px;
    } */
    /* .ownerProfileText {
        margin-left: 7px;
    } */
    /* .ownerProfilePic img{
        border-radius: 8px;
    } */
    /* .ownerProfileText p{
        display: none;
    }
    .ownerProfileTextLarge{
        display: block;
    } */
    .ownerProfile p{
        margin-top: 5px;
    }
    /* .awssld__container{
        padding-bottom: calc(var(--slider-height-percentage) * 1.1) !important;
    } */
}