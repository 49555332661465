/* .businessVerificationProofofOwnershipWhole img{
    width: 100%;
    margin-top: -5px;
} */

.businessVerificationProofofOwnershipWhole{
    background-color: white;
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
}

.navbarDashboardColorBackground {
    background-image: linear-gradient(#FFBE1F, #FFDC29);
    position: absolute;
    top: 0;
    width: 100%;
    /* top: 0; */
    height: 60px;
}

.businessVerificationProofofOwnershipWhole h1{
    padding-top: 75px;
    width: 90%;
    margin: auto;
    font-size: 24px;
    font-weight: 600;
}

.businessVerificationProofofOwnershipWhole p{
    padding-top: 15px;
    padding-bottom: 5px;
    width: 90%;
    margin: auto;
}

.businessVerificationProofofOwnershipExamples{
    background-image: linear-gradient(to left, #FFD242, #FFEC65);
    width: 90%;
    margin: auto;
    /* padding-top: 1px; */
    padding-bottom: 20px;
    margin-top: 20px;
}

.businessVerificationProofofOwnershipSubtitle{
    background-image: linear-gradient(to left, #FFBE1F, #FFDC29);
    width: 96%;
    margin: auto;
    text-align: left;
    
    /* padding-top: 1px;
    padding-bottom: 1px; */
    border-radius: 10px;

}

.businessVerificationProofofOwnershipSubtitle h2{
    font-size: 20px;
    font-weight: 600;
    color: white;
    /* margin-left: 8px; */
    /* margin-top: 10px; */
    padding: 10px;
}

.businessVerificationProofofOwnershipExamples img{
    width: 96%;
}
