.navbarBodyDash{
    background-image: linear-gradient(to bottom right, #FFBE1F, #FFDC29);
    /* position: absolute; */
    width: 100%;
    /* top: 0; */
    height: 60px;
}

.navbarBodyDash p{
    display: inline-block;
    width: 50%;
}

/* .navbarLinks{
    text-align: right;
    display: inline-block;
    float: right;
    height: 60px;
    padding-top: 20px;
    
} */

.navbarBodyDash img{
    height: 50px;
    float: left;
    margin-left: 20px;
    margin-top: 1.5px;
}

/* .navbarLink{
    color: white;
    margin-right: 30px;
    text-decoration: none;
} */