.analyticsWhole{
    /* background-image: linear-gradient(to left, #FFBE1F, #FFDC29); */
    background-color: rgb(253, 253, 253);
}

.analyticsTitle{
    /* width: 80%;
    margin-left: 10%; */
    background-color: white;
    /* background-color: #ffd424; */
    margin-top: 5px;
    padding: 1px;
    border-bottom: 1px solid rgb(243, 243, 243);
    margin-bottom: 25px;
}

.analyticsTitle h3{
    margin-top: 10px;
}

.analyticsWholeInner{
    /* background-image: linear-gradient(to left, #FFBE1F, #FFDC29); */

    /* display: flex; */
}

.analyticsWhole h3{
    /* padding-top: 5%; */
    /* padding-top: 20px; */
    /* margin-bottom: -15px; */
    font-weight: 500;
    color: rgb(165, 165, 165);;
    /* font-size: 15px; */
}

.analyticsNav{
    background-color: #ffd424;
    display: flex;
    height: 45px;
    margin-top: 2px;
    vertical-align: middle;
    align-items: center;
    display: none;
    /* width: 60px;
    height: calc(100vh - 60px);
    border: 4px solid white; */
    /* width: 100%; */
}

.analyticsNav .iconDiv{
    width: max-content;
    margin-bottom: 10px;
    margin-left: 15px;
    margin-top: -4px;
    


}

.analyticsNav .icon{
    
    color: white;
    /* color: rgb(253, 253, 253); */
    /* background-color: white; */
    /* color: rgb(170, 170, 170); */
    /* color:#ffd424; */
    border: 1.5px solid white;
    padding: 6px;
    height: 15px;
    width: 15px;
    margin-top: 15px;
    border-radius: 5px;
    display: inline-block;
}

/* .analyticsNav .ant-btn.ant-btn-icon-only{
    background-color: none;
    width: 100%;
}

.analyticsNav .css-dev-only-do-not-override-19g5zt5 .ant-btn-default{
    background-color: none;
    width: 100%;
} */

.analyticsBody{
    /* height: calc(100vh - 60px); */
    overflow-y: scroll;
    padding-bottom: 40px;
    /* background-color: pink; */
    /* width: calc(100% - 70px); */
    /* margin-left: calc(-50px - 2%); */
}

.analyticsBodyInner{
    text-align: left;
    width: calc(100% - 70px);
    margin: auto;
    margin-left: 35px;
    /* background-color: pink; */
}

/* .analyticsBody h1{margin-top: 0;} */

.summaryDivFirstRow{
    /* background-color: pink; */
    border: 1px solid rgb(240, 240, 240);
    border-radius: 5px;
    padding: 8px;
}

.summaryDivFirstRow p{
    /* background-color: green; */
    height: max-content;
    padding-top: 9px;
    /* font-size: 12.5px; */
    padding-left: 5px;
    font-size: 12.5px;
    color: rgb(69,69,69);
    
}

.summaryDivFirstRow h3{
    /* background-color: pink; */
    margin-top: 7px;
    margin-left: 8px;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 400;
    color: rgb(75, 75, 75);
    /* font-size: 30px; */
}

.summaryDivFirstRowInner{
    display: flex;
    height: 32px;
}

.summaryDivSecondRow{
    /* background-color: pink; */
    display: flex;
    margin-top: 13px;
    margin-bottom: 33px;
}

.summaryDivSecondRowItem{
    width: calc(25%);
    /* margin-right: 10px; */
    height: 50px;
    /* background-color: red; */
    border: 1px solid rgb(240, 240, 240);
    border-radius: 5px;
    text-align: center;
}

.summaryDivSecondRowItem p{
    font-size: 12.5px;
    padding-top: 7px;
    color: rgb(69, 69, 69);
}

.summaryDivSecondRowItem h3{
    font-size: 16px;
    margin-top: 2px;
    font-weight: 400;
    color: #4b4b4b;
}

.analyticsBody table {
    border-spacing: 0px;
}

.analyticsBody table td{
    padding: 0px;
    padding-bottom: 2px;
    padding-right: 7px;
}

.filterReviews button{
    background-color: white;
    height: 33px;
    margin-right: 10px;
    width: 100px;
    border-radius: 5px;
    margin-bottom: 13px;
    color: rgb(165, 165, 165);
    border: 1px solid rgb(236, 236, 236);
}

.navbarDashboardColorBackground{
    background-image: linear-gradient(#FFBE1F, #FFDC29);
    position: absolute;
    top: 0;
    width: 100%;
    /* top: 0; */
    height: 60px;
}

.navbarDashboardColorBackground img{
    height: 50px;
    float: left;
    margin-left: 20px;
}

.analyticsBody p{
    /* color: rgb(179, 140, 0); */
    /* color: rgb(169 132 0); */
    color: rgb(69 69 69);
    margin: 0px;
    /* font-weight: 500; */
}

.individualReviews{
    padding-bottom: 20px;
}

.individualReviewsDiv p {
    /* margin-bottom: 10px; */
    /* margin-top: 5px; */
}
 
.analyticsWhole h1{
    font-size: 20px;
    font-weight: 600;
    /* margin-bottom: 15px; */
    margin-bottom: 20px;
    margin-top: 15px;
    color: rgb(69, 69, 69);
    /* color: white */
    /* margin-top: -10px; */
}

.analyticsBody h2{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.summaryDiv{
    /* background-image: linear-gradient(to left, #FFD242, #FFEC65); */
    /* background-image: linear-gradient(#FFBE1F, #FFDC29); */
    background-color: white;
    /* padding-left: 15px; */
    /* padding-right: 15px; */
    /* padding-top: 5px; */
    /* padding-bottom: 5px; */
    border-radius: 3px;
    /* margin-bottom: 30px; */

    /* color: black; */
    /* border: 5px solid gold; */
}

.summaryDiv p{
    /* color: white; */
    font-weight: 500;
}

.ratingTitle p{
    /* color: black; */
    font-weight: 500;
    font-size: 13px;
    margin-left: 10px;
   
}

.ratingNumber p{
    color: white;
    font-weight: 700;
}


.summaryDivTitles{
    /* background-color: purple; */
    /* width: 15%; */
    float: left;
    height: 100%;
}

.dashboardStarNumberRating{
    padding-left: 5px;
}

.ratingTitle{
    padding-right: 3px;
}

.summaryDivStars{
    /* background-color: aqua; */
    width: 80%;
    display: inline-block;
}

.individualReviewsDiv{
    border-radius: 5px;
    padding-top: 3px;
    /* margin-top: 10px;
     */
    /* background-image: linear-gradient(to left, #FFBE1F, #FFDC29); */
    /* padding: 10px; */
    /* background-color: aqua; */
    /* border: 1px solid yellow; */
}

.individualReviews{
    /* background-color: red; */
}

.individualReviews input{
    /* background-color: pink; */
    height: 27px;
    /* margin-right: 5px; */
    width: calc(100% - 36px);
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    margin-left: 5px;
    font-size: 15px;
}

.individualReviewDiv{
    background-color: white;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 5px;
    padding: 15px;
    padding-top: 11px;
    /* padding-top: 18px; */
    /* padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px; */
    margin-bottom: 10px;
    margin-top: 10px;
}

.individualReviewStatsPopup{
    position: absolute;
    /* float:right; */
    font-size: 12px;
    padding: 8px;
    border-radius: 10px;
    /* background-color: white; */
    background-image: linear-gradient(to left, #fed758, #fdec65);
    border: 3px solid rgb(252, 200, 14);
    /* background-image: linear-gradient(to left, #FFD242, #FFEC65); */
    margin-top: -120px;
    right: 12%;
    /* height: 50%; */
    /* position: absolute; */
    /* left: 50%; */
    width: max-content;
    /* visibility: hidden; */
    /* margin-top: -150px; */
}

#businessAnalyticsInputIcon{
    /* z-index: 100; */
    /* background-color: pink; */
    /* padding-left: 10px; */
    color: rgb(165, 165, 165);
    font-size: 14px;

}

.individualReviewStatsPopup p{

}

.individualReviewRatingNumber p{
    /* color:gold; */
    font-weight: 700;
    color: white;
}

.individualReviewDateDiv{
    /* background-color: cadetblue; */
    /* margin: 0; */
    /* margin-bottom: 5px; */

}

.individualReviewDateDiv p{
    /* width: max-content; */
    display: inline-block;
    margin-left: 8px;
    font-size: 15px;
    /* color: black; */
    color: rgb(75, 75, 75);
    font-weight: 400;
    vertical-align: middle;
    line-height: normal;
    margin-top: -15px;
    /* margin-bottom: 15px; */
}

.individualReviewRatingDiv{
    

    /* background-color: aquamarine; */
}

.individualReviewRatingDiv p{
    display: inline-block;
    /* margin-right: 5px; */
    font-size: 12.5px;
    padding: 0px;
    margin: 0;
}

.individualReviewRatingDiv button{
    /* margin-left: 10px;
    background-color: rgb(245, 244, 241);
    border: none;
    border-radius: 5px;
    height: 28px;
    width:100px; */
    background-color: white;
    color: rgb(165, 165, 165);
    height: 33px;
    margin-left: 12px;
    /* width: 95px; */
    border-radius: 5px;
    border: 1px solid rgb(236, 236, 236);
    font-size: 14px;
}

.individualReviewMoreLargeButton{
    width: 95px;
    display: inline;
}

.individualReviewMoreSmallButton{
    width: 35px;
    display: none;
}

.individualReviewNotesDiv{
    /* background-color: pink; */
    margin-top: 15px;
    margin-bottom: 2px;
    /* margin-left: 5px;
    margin-right: 5px; */
    padding-left: 11px;
    padding-right: 11px;
    padding-top: 7px;
    padding-bottom: 7px;
    min-height: 30px;
    border: 1px solid rgb(240, 240, 240);
    /* border: 1px solid #d9d9d9; */
    border-radius: 2px;
    line-height: 22px;
    background-color: rgb(252, 252, 252);
    color: rgb(75, 75, 75);
    /* background-color: #f0ece4;
    border-radius: 2px;
    padding-left: 8px;
    padding-right: 8px; */
    max-width: 100%;
    font-size: 15px;
}

.individualReviewRatingDivDiv{
    display: flex;
    margin-top: 13px;
}

.individualReviewRatingDivItem{
    width: calc(25%);
    height: 50px;
    border: 1px solid rgb(240, 240, 240);
    border-radius: 5px;
    text-align: center;
}

.individualReviewRatingDivItem p{
    font-size: 12.5px;
    padding-top: 7px;
}

.individualReviewRatingDivItem h3{
    font-size: 16px;
    margin-top: 2px;
    font-weight: 400;
    color: rgb(75, 75, 75);
}

.individualReviewContactDiv{
    /* background-color: pink; */
    /* padding-top: 8px;
    padding-bottom: 8px; */
    font-size: 15px;

}

.individualReviewContactDiv p{
    color: #8d887f;
    /* font-size: 13px; */
}

@media screen and (max-width: 500px) {
    .individualReviewMoreLargeButton{
        display: none;
    }
    
    .individualReviewMoreSmallButton{
        width: 35px;
        display: inline;
    }
    .analyticsBodyInner{
        width: calc(100% - 56px);
        margin-left: 28px;
        /* background-color: pink; */
    }
}