.dashboardDiv{
    position: absolute;
    /* background-image: linear-gradient(to left, #FFD242, #FFEC65); */
    padding-bottom: 70px;
    min-height: 100%;
    width:100%;
}
 
.signOutButton{
    margin-right: 5px;
    background-color: white;
    border: none;
    border-radius: 5px;
    height: 37px;
    width: 90px;
    position: absolute;
    top: 11px;
    right: 15px;
    font-weight: 600;
    z-index: 1000;
}

.dashboardBodyDiv h1{
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-top: -5px;
}

.dashboardBodyDiv h3{
    /* font-size: 16px;
    font-weight: 500;
    color: #ac843c;
    padding-top: 35px; */
    padding-top: 20px;
    font-weight: 500;
    color: #986911;
    font-size: 16px;
}

.dashboardDiv{
    /* background-color: blue; */
}

.dashboardBodyDiv{
    /* background-color: aqua; */
}