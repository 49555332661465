.qrCodeWhole{
    margin-top: 0px;
    
    /* height: 900px; */
    overflow: hidden;
    /* height: 760px; */
    /* padding-bottom: 50px; */
}

.qrCodeWholeInner{
    background-image: linear-gradient(to left, #FFBE1F, #FFDC29);
    height: calc(100vh - 130px);
    min-height: 700px;
    /* background-color: white; */
    /* width: calc(100% - 60px); */
    /* margin: auto; */
    /* height: 100vh; */
}

.qrCodeOptions{
    /* background-color: pink; */
    display: flex;
    width: 86%;
    /* width: calc(90% - 80px); */
    max-width: 850px;
    margin: auto;
    /* margin-top: 0; */
    padding-top: 20px;
}

.qrCodeDownloadDivOuter{
    background-image: linear-gradient(to left, #FFBE1F, #FFDC29);
    /* background-color: black; */
    /* 
    border: 2px solid white; */
    width: 330px;
    /* border: 2px solid white; */

    /* height: 570px; */
    margin-top: 15px;
    margin-bottom: 50px;
    position: relative;
    padding-bottom: 1px;
    /* border-radius: 20px; */
    left:50%;
    transform: translate(-50%, 0%);
}

.qrCodeLogo{
    width: 175px;
    margin-left: -10px;
    margin-top: 2.5px;
    margin-bottom: 4px;

}

#qrCodeSingleLogo{
    /* position: absolute;
    z-index: 100;
    top:65%;
    left: 46.5%; */
    /* position: absolute;
    top:65%;
    left: 47.5%;
    width: 65px; */
    /* margin-top: -600px; */
    width: 45px;
    /* background-color: pink; */
}

.qrCodeDownloadDivInner{
    background-color: white;
    margin: auto;
    width: 264px;
    border-radius: 20px;
    /* height: 420px; */
    padding-bottom: 25px;
    padding-top: 1px;
}


.qrCodeWhole .qrCodeOptionsPlaces{
    width: calc(100%);
    /* background-color: green; */
    margin: auto;
    margin-right: 5px;
    /* float: left; */
}

.qrCodeRestaurantSuggestionsDiv{
    width: calc(83%);
    max-width: 830px;
    /* max-width: 865px; */
    /* margin: auto; */
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    /* left:12%; */
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    display: relative;
    margin-right: auto;
    z-index: 1000;

}

#qrCodeInputIcon{     
    position: absolute;
    padding-top: 13px;
    padding-left: 10px;
}

.qrCodeWhole input{
    /* width: 50%;
    height: 36px;
    border: none;
    border-radius: 3px;
    padding: 8px;
    margin-bottom: 5px;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px; */
    padding-left: 35px;
    /* margin-top: 3%; */
    /* margin-top: 30px; */
    width: calc(100% - 32px);
    height: 36px;
    border-radius: 5px;
    font-size: 15px;
    border: none;
    /* border: 1px solid rgb(241, 241, 244); */
    max-width: 850px;
}

.qrCodeWhole select{
    height: 38px;
    border: none;
    border-radius: 5px;
    padding: 8px;
    margin-bottom: 5px;
    /* margin-left: 37px; */
    margin-left: 5px;
    font-size: 16px;
    width: 12%;
    min-width: 75px;
    margin-top: 5px;
}

.qrCodeWhole button{
    /* height: 36px; */
    border: none;
    /* padding: 8px; */
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 16px;
    background-color: white;
    /* background-color: rgb(255, 200, 0); */
    /* border: 1.5px solid white; */
    /* color: white; */
    margin-top: 5px;
    color: black;
    /* border: 1px solid black; */
    height: 38px;
    border-radius: 5px;
    /* margin-top: 20px;
    margin-bottom: 20px; */
    font-weight: 600;
    /* padding: 10px; */
    /* width: 160px; */
    /* background-color: white; */
}

#downloadQRButtonLarge{
    width: 18%;
    min-width: 140px;
}

#downloadQRButtonSmall{
    display: none;
}

.qrCodeWhole button:hover{
    cursor: pointer;
}

#canvas{
    /* height: 270px;
    width: 270px; */
    /* background-color: pink; */
    margin: auto;
    margin-top: 21px;
    margin-bottom: 21px;
}

.qrCodeWhole h1{
    font-weight: 800;
    font-size: 22px;
    width: 85%;
    margin: auto;
    /* margin-top: 0px; */
    line-height: 27px;
    margin-bottom: 15px;
    
}

.scanDiv{
    background-color: black;
    width: 100px;
    height: 28px;
    color: white;
    font-weight: 700;
    font-size: 19px;
    vertical-align: middle;
    border-radius: 7px;
    padding-top: 4px;
    margin: auto;
    /* margin-top: 22px; */
    margin-top: 100px;
}

.scanDiv p{
    padding-top: 20px;
}

.qrCodeDownloadDivInner h3{
    font-weight: 600;
    /* padding-top: 18px; */
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 3px;
    margin-top: 13px;
}

.qrCodeDownloadDivOuter h2{
    color: white;
    font-weight: 700;
    font-size: 24.5px;
    margin-top: 15px;
    padding-bottom: 1px;
}

@media screen and (max-width: 600px) {
    #downloadQRButtonLarge{
        display: none;
    }
    #downloadQRButtonSmall{
        display: inline;
        /* width: 10%; */
        /* min-width: 50px; */
        width: 50px;
    }
    .qrCodeRestaurantSuggestionsDiv{
        width: calc(81%);
    }

  }