.homepageWhole{
width: 100%;
overflow: hidden;
}

.starfruitHomepageFirstBackgroundDiv{
width: 100%;
  padding: 0;
  position: absolute;
  /* height: 500px; */
  /* height: 80%; */
  height: 660px;
    /* height: 140px; */
  top:60px;
  z-index: -10;
  /* border: 1px solid red; */
  overflow-x: hidden;

}

.starfruitHomepageFirstBackground{
    position: absolute;
    /* top: 330%; */
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.homepageBodyFirstBackground{
    /* width: 100%;
    height: 700px;
    z-index: -1000;
    position: absolute;
    background-color: white; */

}

.starfruitHomepageSecondBackgroundDiv{
    width: 100%;
  padding: 0;
  position: absolute;
  height: 500px;
  top:610px;
  z-index: -10;
    /* border: 1px solid red; */
    /* width: 100%;
  padding: 0;
  position: absolute;
  height: 450px;
  top:600px; */

  /* z-index: -10; */
  overflow: hidden;

}

.starfruitHomepageSecondBackground{
    max-height: 450px;
    position: absolute;
    /* top: 265%; */
    top: 80%;
    left: 48%;
    width: 100%;
    transform: translate(-50%, -50%);
}

.homepageBodySecondBackground{
    width: 100%;
    height: 450px;
    /* padding-top: 3%; */
    z-index: -1000;
    position: absolute;
    background-color: white;
    background-image: linear-gradient(#FFBE1F, #FFDC29);

}

.starfruitHomepageThirdBackgroundDiv{
    width: 100%;
  padding: 0;
  position: absolute;
  height: 580px;
  top:1060px;
  z-index: -8;
    /* border: 1px solid red; */
    overflow: hidden;
    /* width: 100%;
  padding: 0;
  position: absolute;
  height: 450px;
  top:600px; */

  /* z-index: -10; */

}

.starfruitHomepageThirdBackground{
    max-height: 450px;
    position: absolute;
    /* top: 350%; */
    top: 85%; 
    left: 50%;
    width: 110%;
    transform: translate(-50%, -50%);
}

.homepageBodyThirdBackground{
    width: 100%;
    height: 450px;
    /* padding-top: 3%; */
    z-index: -9;
    position: absolute;
    background-image: linear-gradient(#FFBE1F, #FFDC29);

}


.homepageWhole h1{
    font-size: 28px;
    font-weight: 600;
    /* margin-bottom: 25px; */
    margin-top: 60px;
    /* z-index: -90; */
}

.homepageBodyFirst{
    /* position: absolute;
    top: 60px; */
    /* background-image: linear-gradient(to bottom, #FFD242, #FFEC65); */
    /* background-color: white; */
    width: 100%;
    height: calc(100vh - 180px);
    min-height: 660px;
    /* padding-top: 3%; */
    z-index: -100;
    /* margin-top: 60px; */
}

#homepageInputIcon{
    /* padding-right: -20px;
    margin-bottom: -20px; */
    /* position: absolute;
    z-index: 100;
    top:calc(100px); */
    position: absolute;
    padding-top: 13.5px;
    padding-left: 10px;
    margin-top: 44px;
    /* padding-right: -30px; */

}

.homepageBodyFirst input{
    padding-left: 37px;
    /* margin-top: 3%; */
    margin-top: 44px;
    width: 70%;
    height: 34px;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    max-width: 750px;
    padding-top: 4px;
    /* position: absolute;
    left:50%;
    transform: translate(-50%, -50%); */
}

.homepageBodyFirstText{
    width: 80%;
    margin: auto;
    /* margin-top: 30px; */
    /* color: #ac843c; */
    /* color: white; */
    /* color: rgb(179, 140, 0); */
    color: rgb(169 132 0);
    font-weight: 500;
    font-size: 17px;
    line-height: 1.45;

}

.homepageRestaurantSuggestionsDiv{
    /* width: calc(76% + 15px); */
    width: calc(70% + 23px);
    
    max-width: 773px;
    /* margin: auto; */
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    /* left:12%; */
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    /* max-width: 736px; */
    /* right:0; */




    /* left: calc(10.5%); */
    position: absolute;
    
    /* padding-bottom: 10px; */
    /* padding-top: 5px;
    padding-bottom: 10px; */
}

/* .placesAutocompleterow{

} */

.starfruitHomepageFirstInfographic{
    width: 75%;
    max-width: 410px;
    min-width: 320px;
    margin-bottom: 40px;
    /* position: absolute;
    top: 300px;
    left: 6%; */
    margin-top: 44px;
}

.homepageBodySecond{
    /* position: absolute;
    top: calc(100%); */
    /* background-image: linear-gradient(#FFBE1F, #FFDC29); */
    width: 100%;
    height: calc(450px);
    /* padding-top: 3%; */
    text-align: left;
    z-index: -11;
}

.starfruitHomepageSecondInfographic {
    /* height:230px; */
    /* background-color: green; */
    /* margin: auto; */
    /* margin-left: 2%;
    margin-top: 10px; */
    /* float: left; */
    height: 100%;
    /* max-width: 500px; */
    float: left;
    margin-left: 8%;
    max-height: 320px;
    /* text-align: right; */
}

.homepageBodySecond h1{
    color: white;
    margin-left: 25px;
}

.homepageBodySecond h3{
    display: inline;
    font-size: 18px;
    font-weight: 500;
    margin-right:3px;
    margin-left: 8px;
    /* background-color: aquamarine; */
}

.homepageBodySecond p{
    display: inline;
    /* font-size: 18px; */
    /* background-color: aqua; */
    vertical-align: top;
    color: #ac843c;
    /* font-weight: 400; */

}

.homepageBodySecondContent{
    /* background-color: aqua; */
    margin: auto;
}

.homepageBodySecondPhone {
    width: 40%;
    /* float: left; */
    background-color: orange;
}

.homepageBodySecondSteps{
    /* width: 55%; */
    /* background-color: pink; */
    /* float: left; */
    margin: auto;
}

.homepageBodySecondSteps table{
    margin: auto;
    margin-top: 30px;
    /* margin-right: 15px; */
    /* margin-left: -5px; */
    width: 100%;
    max-width: 950px;
    
    /* 
    margin: auto; */
    /* background-color: aquamarine; */

}

.homepageBodySecondSteps table td{
    vertical-align: middle;
    padding-top: 8px;
    padding-bottom: 8px;
}

.homepageBodySecondSteps table td:nth-of-type(2) {
    padding-top: 2px;
  }

  .homepageBodySecondSteps table td:nth-of-type(3) {
    padding-top: 2px;
  }

.homepageBodySecondSteps table h3{
    margin-left: 10px;
    font-size: 20px;
    padding: 4px;
    /* padding-left: 8px; */
    padding-right: 10px;
    /* background-color: #ac843c; */
    
}

/* .homepageBodySecondSteps table td[2{
    margin-top: 10px;
} */

.homepageBodySecondStep{
    margin-bottom: 20px;
    margin-top: 20px;
}

.homepageBodyStepsBackground{
    background-color: white;
    margin-right: 6%;
    border-radius: 5px;
    margin-top: 15px;
    max-width: 420px;
    padding-right: 5px;

}

.homepageBodyThird{
    /* position: absolute;
    top: calc(200%); */
    /* background-image: linear-gradient(to bottom, #FFD242, #FFEC65); */
    width: 100%;
    height: calc(550px);
    /* padding-top: 3%; */
    /* text-align: right; */
}

.homepageBodyThird h1{
    /* margin-right: 25px; */
}

.homepageBodyThird p{
    margin: auto;
    text-align: center;
    /* margin-right: 25px; */
    width: 80%;
    color: #ac843c;
}

.homepageBodyThirdText{
    background-color: white;
    width: 80%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding-top: 8px;
    padding-bottom: 8px;
    max-width: 700px;
    /* display: inline-block; */
    
}

.starfruitHomepageThirdInfographic {
    width: 38%;
    max-width: 200px;
    /* position: absolute;
    top: 300px;
    left: 6%; */
    padding-top: 20px;

}

@media screen and (max-width: 500px) {
    .homepageWhole h1{
        margin-top: 70px;
    }
    .homepageBodyFirst{
        height: calc(100vh - 200px);
    }
}