.feedbackFormWhole{
    position: absolute;
min-height:100%;
    /* background-image: linear-gradient(to left, #FFD242, #FFEC65); */
    /* background-image: linear-gradient(to left, #ffc234, #fde766); */
    /* background-image: linear-gradient(to top left, #feaa0d, rgb(255, 255, 130)); */
    background-image: linear-gradient(to left, #FFBE1F, #FFDC29);
    width: 100%;
    /* padding-bottom: 10px; */
    overflow-y: hidden;
    overflow-x: hidden;
    /* color: rgb(255, 213, 0); */
    /* color:#ffce1c; */
    /* color: #f9c22a; */
}

.feedbackFormWholeInner{
    position:relative;
    z-index: 100;
}

.feedbackFormModal{
    position: absolute;
    z-index: 200;
    top: 0;
    left:0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .7);
}

.feedbackFormModalInner{
    position: absolute;
    z-index: 201;
    width: 60%;
    max-width: 400px;
    min-width: 320px;
    padding-bottom: 36px;
    top: 18%;
    left: calc(50%);
    transform: translate(-50%);
    /* background-color: white; */
    /* background-image: linear-gradient(to bottom right, #FFBE1F, #FFDC29); */
    background-color: white;
    /* background-color: rgba(256,212,36, 1); */
    border-radius: 5px;

}

.feedbackFormModalInnerInner{
    width: 85%;
    margin: auto;
    /* padding: 10px 0px 10px 10px; */
    
}

.feedbackFormModal h2{
    font-size: 17px;
    /* background-image: linear-gradient(to left, #FFBE1F, #FFDC29); */
    /* background-color: white; */
    /* color: white; */
    /* color: black; */
    /* border-bottom: 3px solid white; */
    /* height: 50px; */
    /* color: white; */
    padding-top: 28px;

    font-weight: 600;
    border-radius: 3px;
    margin-top: 3px;
}

.feedbackFormModal p{
    /* padding-top: 2px; */
    /* color: #724e0b; */
    font-size: 16px;
    line-height: 1.45;
    margin-bottom: 10px;
    margin-top: 20px;
    /* color: #986911; */
    /* color: rgb(179, 140, 0); */
    /* color: rgb(169 132 0); */
    color: rgb(165, 165, 165);
}


.feedbackFormWhole button:hover{
    cursor: pointer;
}

.feedbackFormModal button{
    /* background-image: linear-gradient(to right, #FFBE1F, #FFDC29); */
    /* background-color: white; */
    /* background-color: rgba(256,212,36, 1); */
    margin-top: 5px;
    margin-bottom: 5px;
    height: 35px;
    /* border: 1px solid #986911;; */
    /* color: white; */
    height: 45px;
    width: 150px;
    /* font-size: 15.5px; */
    font-size: 17px;
    /* color: rgb(165, 165, 165); */
    border: none;
    border-radius: 5px;

    font-weight: 600;
    background-color: white;
    border: 1.5px solid rgb(240, 240, 240);
    /* color: white; */
    /* border: 2px solid rgb(255, 213, 0); */
    /* background-color: rgb(255, 213, 0); */
   
}

.feedbackFormModal h3{
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 23px;
    font-size: 15.5px;
}

.feedbackFormModal button p{
    margin-left: 5px;
}


.starRatingDiv{
   padding-bottom: 3px;
   /* background-color: lightgray; */
   width: 70%;
   padding-left: 10px;
   padding-right: 10px;
   margin: auto;
    /* background-image: linear-gradient(to right, #feba1b, #FFEC65);
    padding: 3px;
    padding-left: 20px;
    padding-right: 20px;
    width: max-content;
    margin: auto;
    border-radius: 15px; */
}

.feedbackFormSteps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon{
    color: rgba(0, 0, 0, 0.25);
}

.feedbackFormSteps .ant-steps-item-icon{
    margin: 0;
}

.feedbackFormSteps .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    /* background: rgb(255, 200, 0); */
    background: white;
}

.feedbackFormSteps .ant-steps-item-process .ant-steps-item-icon{
    /* border-color: rgb(255, 200, 0); */
    border-color: white;
}

.feedbackFormSteps .ant-steps-item-wait .ant-steps-item-icon{
    border-color: white;
}

.feedbackFormSteps .ant-steps-item-title::after{
 height: 2px;
 
}

.feedbackFormSteps{
    width: 80%;
    margin: auto;
    max-width: 270px;
    /* background-color: pink; */
    margin-top: 20px;
    margin-bottom: 10px;

}

.feedbackFormWhole input{
    font-size: 16px;
}

.feedbackFormWhole textarea{
    font-size: 16px;
}

.feedbackFormPageNumber{
    /* color: #ffc800; */
    /* color: #b37523; */
    /* color: #dba736; */
    color: white;
    /* position: absolute; */
    font-size: 16px;
    /* top:55px; */
    /* left: 10px; */
    font-weight: 700;
    width: 100%;
    /* background-image: linear-gradient(to top , #ffc823, rgb(255, 216, 74)); */
    /* background-color: white; */
    /* height: 40px; */
    margin-top: 0px;
    text-align: left;
    padding-left: 15px;
    padding-top: 10px;
    /* color: #fbc70c; */
    /* color: white; */
    /* color: #986911; */
    /* margin-bottom: 15px; */
}


.feedbackFormBody{
    width: 200%;
    /* margin-left: -100%; */
    transition: margin-left 0.5s ease-in-out;
}

/* .feedbackFormBodyInner{
    position: absolute;
    background-color: white;
    width: 80%;
    left: 10%;
    height: 57%;
    top: 23%;
} */

.feedbackFormTitle h4{
    /* padding-top: 5%; */
    /* padding-top: 20px; */
    /* margin-bottom: -5px; */
    font-weight: 500;
    color: #986911;
    font-size: 16px;
    /* margin-bottom: 10px; */
    /* color: white; */
}

.feedbackFormWhole h1{
    /* text-align: center;
    text-align: right; */
    font-size: 24px;
    font-weight: 600;
    /* border-bottom: 1px solid lightgray; */
    /* padding-bottom: 10px; */

    margin: auto;
    /* padding-top: 3px; */
    margin-bottom: 33px;
   /* padding-top: 30px; */
    /* margin-bottom:20px; */
    width: 85%;
    /* text-align: center; */
    /* color: #fac000; */
}

.feedbackError {
    /* display: none; */
    margin: auto;
    color: #ff0033;
    width: 100%;
    font-size: 16px;
    padding-top: 7px;
    /* margin-top: 35px; */
    /* margin-bottom: 3px; */
    /* margin-top: 35px; */
}

.feedbackRatingTitle{
    margin-top:25px;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 15px;
    /* color: rgb(180, 180, 180); */
    /* color: rgb(179, 140, 0); */
    /* color: rgb(169 132 0); */
    color: rgb(69, 69, 69);


}

.feedbackRatingTitle p{
    /* background-color: green; */
    margin-top:30px;
    margin-bottom: 8px;
    /* font-weight: 700; */
    /* color: rgb(255, 191, 0); */
    /* color: rgb(180, 180, 180); */
    /* color: #ffe51d; */
}

.feedbackFirstPage{
    width: 50%;
    height: 85%;
    /* padding-top: 5px; */
    float: left;
        /* background-color: white;
    width: 80%;
    margin: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    border-radius: 10px; */
    /* padding-top: 8px; */
    /* background-color: pink; */
    /* position: absolute; */
    /* top:  */
}

.feedbackFirstPage p{
    /* font-weight: 500; */
    /* padding-top: 5px; */
    /* color: white; */
}

.feedbackFirstPageInner{
    /* background-color: white;
    width: calc(60%);
    margin: auto;
    height: 650px;
    padding-top: 5px;
    border-radius: 10px; */
    /* margin-top: 40px; */

}

.feedbackWhiteBackground{
    position: absolute;
    width: calc(84%);
    height: calc(100% - 200px);
    /* background-color: white; */
    background-color: rgba(255, 255, 255, 1);
    top: 155px;
    left: 8%;
    border-radius: 7px;


    /* width: calc(100% - 80px); */
    /* left: 40px; */
    /* top: 0;
    background-color: white;
    width: calc(100% - 60px);
    margin: auto;
    padding-top: 1px;
    padding-bottom: 1px;
    border-radius: 10px; */

}
.feedbackSecondPage{
    width: 50%;
    height: 85%;
    display: inline-block;
    /* background-color: antiquewhite; */
}

.feedbackSecondPageElements{
    width: 68%;
    margin: auto;
}

.feedbackSecondPage p{
    text-align: left;
    margin-top: 25px;
    margin-bottom: 7px;
    /* font-weight: 600; */
    font-weight: 500;
    font-size: 15px;
    /* color: rgb(180, 180, 180); */
    /* color: rgb(179, 140, 0); */
    /* color: rgb(169 132 0); */
    color: #454545;
}

.feedbackSecondPageInstructions{
   margin-top: -17px;
}

.feedbackSecondPageInstructions p{
    /* color: #d4a43b; */
    color: rgb(180, 180, 180);
    font-weight: 400;
    margin-top: 25px;
    /* color: #ffea00; */
    /* color:#d09320; */
 }

 .feedbackSecondPage .ant-picker {
    border: none;
    border-radius: 3px;
    width: 100%;
    padding: 6px 11px 6px;
    margin-bottom: 7px;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 3px;
    margin-top: 5px;
    /* min-width: 250%; */
    /* text-align: left; */
 }

.feedbackSecondPage textarea{
    width: calc(100% - 18px);
    height: 150px;
    border: none;
    padding: 8px;
    margin-bottom: 2px;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 3px;
    margin-top: 5px;
}

.feedbackSecondPageElements input{
    width: calc(100% - 12px);
    height: 36px;
    border: none;
    padding-left: 8px;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 3px;
    margin-top: 5px;

}

.FeedbackFormButtonField{
    /* position: absolute; */
    /* bottom: 0px;
    margin-bottom: 40px; */
    /* background-color: blue; */
    /* left: calc(30%); */
    margin-bottom: 100px;
    margin-top: 7px;
}
 
.feedbackFirstPage button {
    height: 40px;
    width: 110px;
    font-size: 15.5px;
    /* color: rgb(165, 165, 165); */
   /* background-color: white; */
   border: 1.5px solid rgb(236, 236, 236);
    font-weight: 500;
    border-radius: 5px;
    margin-top: 28px;
}

.feedbackSecondPage button {
height: 40px;
    width: 110px;
    font-size: 15.5px;
    /* color: rgb(165, 165, 165); */
   /* background-color: white; */
   border: 1.5px solid rgb(236, 236, 236);
    font-weight: 500;
    border-radius: 5px;
    margin-top: 28px;
}

.feedbackSecondPage button:first-child {
    margin-right: 13px;
}

.FeedbackFormButtonField button:first-child {
    /* margin-right: 15px; */
    /* background-color: blue; */
}

.feedbackGratitudePopup{
    background-image: linear-gradient(to left, #FFBE1F, #FFDC29);
    z-index: 1000;
    /* margin-left: 25%; */
    width: 100%;
    min-height: calc(100% + 50px);
    height: 100vh;
    position: absolute;
    top: -43px;
    visibility: hidden;
    opacity: 0;
    /* background-color: aquamarine; */
    transition: visibility 0s, opacity 0.1s linear;
}
 
.feedbackGratitudePopup p{
    font-weight: 500;
    margin-top: 70px;
    font-size: 17px;
    margin-bottom: 20px;
    color: rgb(169 132 0);
    /* color: rgb(179, 140, 0); */
}

.feedbackGratitudePopup img{
    margin-top: 10px;
    height: 300px;
    margin-left: -20px;
    display: inline;
}

.feedbackGratitudePopup button{
    /* height: 35px;
    width: 150px;
    background-color: white;
    border: none;
    border-radius: 5px;
    margin-top: 45px; */
    height: 44px;
    width: 190px;
    font-size: 15.5px;
    /* color: rgb(165, 165, 165); */
   background-color: white;
    /* border: 1.5px solid rgb(236, 236, 236); */
    border: none;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 50px;
}

.feedbackGratitudePopupInner {
    /* padding-top: 20%; */
}