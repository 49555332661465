.businessVerificationForm{
    /* background-image: linear-gradient(to bottom, #FFD242, #FFEC65); */
    /* background-color: white; */
    width: 76%;
    margin: auto;
    /* padding-top: 1px; */
    /* padding-bottom: 35px; */
    /* padding: 3px; */
    /* height:100%; */
}

.businessVerificationForm form{
    /* background-color: white; */
    /* padding: 10px; */
    /* padding-top: 10px; */
    /* margin-top: 5px; */
    padding-bottom: 15px;
}

.businessVerificationForm p{
    /* margin-top: 20px; */
    /* font-size: 15px; */
    /* color: #ac843c; */
    text-align: left;
    margin-top: 15px;
}

.businessVerification h1{
    padding-bottom: 5px;
}

.businessVerificationForm p{
    margin-bottom: 6px;
}

.businessVerificationForm input{
    border: none;
    border-radius: 3px;
    height: 38px;
    width: 100%;
    padding:8px;
    /* border: 2px solid #ffcf60; */
    /* background-color: rgb(245, 244, 241); */
    margin-bottom: 15px;
    font-size: 14px;
    border: 1px solid lightgray;
}

.verificationFormRestaurantSuggestions{
    /* border: 1px solid orange; */
    /* margin: auto; */
    position: absolute;
    /* margin-top: -15px; */
    /* background-color: aqua; */
    height: 45px;
    padding: 10px;
    border: 1px solid lightgray;

}

.verificationFormRestaurantSuggestionsOuter{
    /* border: none;
    width: 48%;
    position: absolute;
    margin-top: -15px;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 1; */

}
.restaurantInput{
    /* padding-bottom:20px; */
}

.proofOfQwnershipTitle{
    margin-top: 15px;
}

.businessVerificationForm a{
    font-size: 16px;
    /* margin-top: 100px; */
    /* position: absolute; */
    /* color: rgb(255, 170, 11); */
    color: #0000EE;
    text-decoration: none;

    /* padding-bottom: 20px; */
}

.businessVerificationForm input[type="file"] {
    /* display: none; */
    margin-top: 15px;
}

#businessverificationproofofownershipIcon{
    margin-left: 5px;
}

.fileUploadDiv{
    height: 25px;
    /* width: 110px; */
    width: 50%;
    background-color: rgb(245, 244, 241);
    /* border: 1px solid rgb(255, 196, 0); */
    border-radius: 5px;
    padding: 3px;
    padding-top: 8px;
    padding-bottom: 1px;
    margin: auto;
    font-size: 15px;
    margin-bottom: 30px;
    color: gray;
}

.businessVerificationForm select{
    width: 65%;
    /* border: none;
    border-radius: 5px;
    height: 26px;
    width: 60%;
    padding: 3px; */
    color: gray;
    border: none;
    border-radius: 5px;
    height: 40px;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 5px;
    /* border: 2px solid #ffcf60; */
    background-color: rgb(245, 244, 241);
} 

.errorLabel{
    text-align: left;
    color: #ff4423;
    position: absolute;
    /* width: 50%; */
    /* left:50%;
    transform: translate(-50%, 50%); */
    /* margin-top: -100px; */
    font-size: 14px;
    /* padding-left: 5px; */
    /* background-color: #FFD242; */
}

.errorLabelFile{
    text-align: left;
    color: #ff4423;
    position: absolute;
    width: 50%;
    left:50%;
    transform: translate(-50%, 50%);
    margin-top: -20px;
    font-size: 12px;
    padding-left: 5px;
    /* background-color: #FFD242; */
}

.errorLabelSelect{
    text-align: left;
    color: #ff4423;
    position: absolute;
    width: 50%;
    left:50%;
    transform: translate(-50%, 50%);
    margin-top: -10px;
    font-size: 12px;
    padding-left: 5px;
    /* background-color: #FFD242; */
}


/* .errorLabel p{
    font-size: 12px;
} */

.errorLabel:nth-child(1) {
    margin-top: -20px;
}

.businessVerificationForm button{
    /* background-color: white; */
    background-image: linear-gradient(to top, #FFD242, #FFEC65);
    color: white;
    border: none;
    border-radius: 5px;
    height: 35px;
    width: 100px;
    margin-top: 30px;
    margin-bottom: 30px;
}

.businessVerificationFormButtonField{
    width: 100%;
    
}