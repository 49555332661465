/* .businessAnalyticsChartHide{
    background-color: rgba(0, 0, 0, .2);
    width: calc(100% - 63px);
    height: 330px;
    z-index: 200;
    margin-top: -7px;
    margin-left: -7px;
    position: absolute;
} */

.inProgress{
    font-size: 14px;
    padding-bottom: 10px;
    font-weight: 400;
}

.datePickerBusinessAnalytics input{
    height: 24px !important;
}

.businessAnalyticsChart .plot{
    display: flex;
    /* background-color: white; */
    margin-top: 10px;
    border-radius: 3px;
    margin-bottom: 33px;
}

.businessAnalyticsChart .plot p{
    /* color: #ac843c;  */
    color: rgb(155, 155, 155);
    /* color: rgb(255, 111, 0);
    color: rgb(255, 149, 0);
    color: rgb(255, 183, 0);
    color: rgb(255, 217, 0);
    color: rgb(255, 234, 0);

    color: rgb(255, 179, 0);
    color: rgb(255, 123, 0);
    color: rgb(255, 247, 0);
    color: rgb(77, 255, 0);
    color: rgb(0, 242, 255); */
    

}

.businessAnalyticsChart .plotGraph{
    width: 100%;
    height: 210px;
    /* border: 1px solid yellow; */
    /* z-index: -100; */
    /* display: "inline-block", */
}