body{
    /* background-image: linear-gradient(#FFBE1F, #FFDC29);
    height: 60px; */
    /* margin-bottom: 20px; */
    /* position: fixed;
    bottom: 0px; */
    /* position: absolute;
    top: 100vh; */
}
.footerBody{
    /* position: relative; */
    background-image: linear-gradient(to top left, #FFBE1F, #FFDC29);
    height: 70px;
    /* padding-bottom: 30px; */
}

.footerBody p{
    color: white;
    /* font-size: 18px; */
    font-weight: 600;
    /* margin-bottom: 5px; */
    
}

.footerCopyright{
    float: left;
    margin-left: 20px;
    /* background-color: pink; */
    margin-top: 10px;
}

.footerLinks{
    float: right;
    margin-right: 15px;
    margin-left: 5px;
    /* background-color: green; */
    margin-top: 10px;
}
.footerIcon{
    /* background-color: aqua; */
    /* display: max-content; */
    text-align: left;
    margin: auto;
    margin-right: 10px;
    margin-left: 5px;
    display: inline-block;
    margin-top: 7px;
    /* margin-bottom: -10px; */
}

.footerIcon p{
    /* height:10px;
    width: 20px; */
    color: #ac843c;
    display: inline-block;
    margin-left: 10px;
    font-size: 17px;
}

/* .hello{
    width: max-content;
    margin: auto;
    margin-top: 10px;
} */
