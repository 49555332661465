.businessVerificationNew{
    /* position: absolute;

    top: 0;
    bottom:0;
    left:0;
    right:0;
    z-index: -20;
    background-image: linear-gradient(#FFBE1F, #FFDC29); */
    margin-top: 0px;
    position: absolute;
    background-image: linear-gradient(to left, #FFBE1F, #FFDC29);
    height: 100vh;
    z-index: -200;
    width: 100%;
    overflow: hidden;
}

.verificationWhiteBackground{ 
    position: absolute;
    width: calc(84%);
    /* height: calc(100% - 310px); */
    height: calc(500px);
    background-color: rgba(255, 255, 255, 1);
    top: 190px;
    left: 8%;
    border-radius: 7px;
    z-index: -20;
}

.businessVerificationPageNumber p{
    color: white;
    /* position: absolute; */
    font-size: 16px;
    /* top:55px; */
    /* left: 10px; */
    font-weight: 700;
    width: 100%;
    /* background-image: linear-gradient(to top , #ffc823, rgb(255, 216, 74)); */
    /* background-color: white; */
    /* height: 40px; */
    margin-top: 0px;
    text-align: left;
    padding-left: 15px;
    padding-top: 10px;
}

.businessVerificationPageNumber p{
    margin-bottom: .5em;
    
}

.businessVerificationNew h1{
    font-size: 24px;
    font-weight: 600;
    /* border-bottom: 1px solid lightgray; */
    /* padding-bottom: 10px; */
    margin: auto;
    /* padding-top: 3px; */
    margin-bottom: 33px;
    /* padding-top: 30px; */
    /* margin-bottom: 20px; */
    width: 85%;
    /* text-align: center; */

}

.businessVerificationNew form{
    width: 300%;
    display: flex;
}

.verificationError{
    margin: auto;
    color: #ff0033;
    width: 100%;
    font-size: 16px;
    padding-top: 7px;
}

.businessVerificationNew form p{
    text-align: left;
    margin-top: 25px;
    margin-bottom: 7px;
    font-size: 15px;
    /* color: rgb(180, 180, 180); */
    /* color: rgb(179, 140, 0); */
    color: rgb(69, 69, 69);
    font-weight: 500;

}

.businessVerificationContent{
    width: 68%;
    margin: auto;
    /* background-color: white; */
}

.businessVerificationNew h3{
    margin-top: 0px;
    padding-top: 10px;
    color: #986911;
    margin: auto;
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 600;
    /* color: rgb(180, 180, 180); */
    color: rgb(179, 140, 0);
    width: 90%;
}

.verificationFormRestaurantSuggestionsOuter{
    /* width: calc(60%);
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-top: -3px;
    margin-left: auto; 
    margin-right: auto;
    position: absolute; */
    /* width: calc(76% + 15px); */
    /* border: 1px solid red; */
    width: calc(67% - 20px);
    
    max-width: 865px;
    /* margin: auto; */
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    /* left:12%; */
    position: absolute; 
    left: 0; 
    right: 0; 
    margin-left: auto; 
    margin-right: auto;
    /* max-width: 736px; */
    /* right:0; */




    /* left: calc(10.5%); */
    position: absolute;
    
    /* padding-bottom: 10px; */
    /* padding-top: 5px;
    padding-bottom: 10px; */

}

.businessVerificationNew input{
    /* width: 100%;
    height: 36px;
    border: none;
    border-radius: 3px;
    padding: 8px;
    margin-bottom: 5px;
    font-size: 16px; */
    width: calc(100% - 12px);
    /* width: calc(80%); */
    height: 36px;
    border: none;
    padding-left: 8px;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 3px;
    margin-top: 5px;
    font-size: 16px;
    margin-bottom: 7px;

}

.businessVerificationNew select{
    width: calc(100%);
    height: 36px;
    border: none;
    padding-left: 8px;
    border: 1px solid rgb(236, 236, 236);
    border-radius: 3px;
    margin-top: 5px;
    font-size: 16px;
    margin-bottom: 7px;
}

.businessVerificationNew .businessVerificationFirstPage{
    width: 33.33%;
    /* margin-left: -33.33%; */
    /* background-color: pink; */
}

.businessVerificationNew .businessVerificationSecondPage{
    width: 33.33%;
    /* background-color: green; */

    
}

.businessVerificationNew .businessVerificationThirdPage{
    width: 33.33%;
    /* background-color: blue; */

}

.businessVerificationNew .businessVerificationButtonField{
    margin-bottom: 100px;
    margin-top: 5px;
}

.businessVerificationNew h5{
    color: rgb(180, 180, 180);
    font-weight: 600;
    font-size: 15.5px;
    margin-top: 10px;
    margin-bottom: 0px;
    /* color: #ffea00; */
}

.businessPhoneVerification{
    margin-bottom: 30px;
}

.businessPhoneVerification .phoneVerificationIcon{
    margin-right: 8px;
    margin-left: 5px;
}

.businessPhoneVerification button{
    width: 100%;
    /* background-color: white; */
    border: none;
    /* border: 1px solid darkgray; */
    border-radius: 3px;
    text-align: left;
    margin-top: 12px;
    background-color: rgb(247, 247, 247);

    height: 40px;
    font-size: 15px;
    /* color: rgb(165, 165, 165); */
    /* background-color: white; */
    border: 1.5px solid rgb(245, 245, 245);
    /* font-weight: 500; */
}

.businessVerificationNew .businessVerificationButtonField button:first-child{margin-right: 15px;}

.businessVerificationNew .businessVerificationButtonField button{
    height: 36px;
    width: 110px;
    font-size: 15.5px;
    /* color: rgb(165, 165, 165); */
    /* background-color: white; */
    border: 1.5px solid rgb(236, 236, 236);
    font-weight: 500;
    border-radius: 5px;
    margin-top: 28px;
}