.navbarBody{
    background-image: linear-gradient(to bottom right, #FFBE1F, #FFDC29);
    /* position: absolute; */
    width: 100%;
    /* top: 0; */
    height: 60px;
}

.navbarBody p{
    display: inline-block;
    width: 50%;
}

.navbarLinks{
    text-align: right;
    display: inline-block;
    float: right;
    height: 60px;
    padding-top: 20px;
    font-weight: 600;
    font-size: 16px;
    padding-right: 5px;
}

.navbarBody img{
    height: 50px;
    float: left;
    margin-left: 20px;
    margin-top: 1.5px;
}

.navbarLink{
    color: white;
    margin-right: 20px;
    text-decoration: none;
}

.navbarLink:hover{
    color: white;
}

